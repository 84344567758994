$auto-grid-min-size: 165px; // Dimensione minima delle celle
$aspect-ratio: 208 / 165;

.attachments {
  display: flex;
  flex-direction: column;
  padding: 25px;
}
.attachments-content {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.attachments-choose-disposition {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  I {
    font-size: 20px;
    &:hover {
      cursor: pointer;
    }
    &.active {
      color: #369bff;
    }
  }
  gap: 16px;
  color: #606060;
}
.attachments-actions {
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: sticky;
  top: 20px;
  padding-top: 20px;
  border-top: 1px solid black;
}
.attachments-grid-search {
  display: flex;
  justify-content: flex-end;
}
.attachments-grid {
  flex: 1;
  height: auto;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax($auto-grid-min-size, 1fr));
  grid-auto-rows: auto;
  grid-gap: 1rem;
  padding: 40px 0;
  overflow-y: auto;
}
.attachments-square {
  flex: 1;
  height: auto;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: auto;
  grid-gap: 1rem;
  padding: 40px 0;
  overflow-y: auto;
  .cell {
    .cell-text {
      margin-top: 20px;
      font-size: 14px !important;
      font-style: normal !important;
      font-weight: 700 !important;
      color: #036 !important;
    }
  }
}

.favorite-icon {
  position: absolute;
  right: 5px;
  top: 5px;
  cursor: pointer;
  I {
    font-size: 22px;
    color: #369bff;
  }
}
.attachments-list {
  TR {
    border-bottom-color: #000 !important;
    font-size: 14px !important;
  }
  TD {
    padding: 0 5px !important;
  }
  .attachments-list-row {
    max-height: 34px;
    padding: 5px;
  }
  .row-list-text {
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 700 !important;
    color: #036 !important;
  }
  .row-list-image {
    .image-no-preview {
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid black;
      height: 60px;
      width: 60px;
      border-radius: 25%;
    }
    IMG {
      height: 70px;
      width: 70px;
      border-radius: 25%;
    }
  }
  .favorite-icon {
    position: static;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 5px;
    i {
      font-size: 15px;
    }
  }

  .cell-image {
    width: 20px;
    height: 20px;
    border-radius: 6px;
  }
}
.cell {
  position: relative;
  width: 100%;
}

.cell-image {
  overflow: hidden;
  background-color: #cce5ff;
  width: 100%;
  min-height: 203px;
  max-height: 203px;
  border-radius: 12px;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  IMG {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
.cell-text {
  color: var(--csk-3699-ff-800, #036);
  text-align: center;
  width: 95%;
  height: fit-content;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  .cell-text-date {
    font-size: 12px !important;
  }
}
input[type="file"] {
  border: 1px solid #ecf0f3;
  border-radius: 6px;
  padding: 6px 15px;
  cursor: pointer;
}
input[type="file"]::file-selector-button {
  border: none;
  outline: none;
  box-shadow: none;
  padding: 10px;
  border-radius: 5px;
  text-transform: capitalize;
  font-size: 12px;
  width: fit-content;
  min-width: 105px;
  @extend .button-primary-extended;
}

.subdirectory-section {
  border-bottom: 1px solid black;
  padding: 20px;
  .folder{
    border-radius:12px;
    border: 2px solid transparent;
    cursor: pointer;
    &:hover{
     
      border: 2px solid rgb(54, 155, 255)
    }
  }
  .iconSubdirectory {
    font-size: 60px;
  }
  //   .cell-text {
  //     margin-top: 20px;
  //     font-size: 14px !important;
  //     font-style: normal !important;
  //     font-weight: 700 !important;
  //     color: #036 !important;
  //   }
}

.attachments-directory-download {
    i {
    font-size: 18px;
    &:hover {
      cursor: pointer;
    }
    &.active {
      color: #369bff;
    }
  }
}

.attachments-directory-delete {
    i {
    font-size: 18px;
    &:hover {
      cursor: pointer;
    }
    &.active {
      color: #f1416c;
    }
  }
}
