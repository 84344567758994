.offcanvas {
  width: 70% !important;
  max-width: 50%;
 
}

.drawer, .drawer-overlay{
  top: var(--bs-app-header-height);
}
.offcanvas-assessments {
  @extend .offcanvas;
  width: 893px !important;
  .dynamic-editor-label {
    font-size: 16px;
  }
}

.offcanvas-curators {
  @extend .offcanvas;
  // width: 492px !important;
}
.offcanvas-companies,
.offcanvas-mail_models {
  @extend .offcanvas;
  // width: 893px !important;
}
.second-panel {
  display: flex;
  flex-direction: column;
  
  .offcanvas-body {
    flex-grow: 1;
    padding: 0 30px 40px;
    overflow-y: auto;
  }
}
.boldNormalSize {
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.boldSize {
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.header-offcanvas {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  padding: 10px 20px 5px 20px;
  i {
    font-size: 20px !important;
  }
}
.offcanvas-body {
  padding-top: 0;
}
.WysiwygEditor {
  width: 100%;
}
.container-panel {
  height: 100vh;
  display: flex;
  .panel {
    padding: 0 20px;
  }
}
.title-details {
  color: #1b283f;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
}
.credential-title {
  color: #1b283f;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.subtitle {
  color: #1b283f;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.sectionIcon {
  flex: none;
  justify-content: space-between;
  gap: 20px !important;
  flex-wrap:wrap;
  & > div {
    flex: none !important;

    .wrapper-dynamic-editor {
      flex: none;
      min-width: auto;
      width: fit-content;
    }
  }
}
.columnLabel {
  .dynamic-input {
    flex-direction: column;
    align-items: start;
  }
}
.columnLabel2 {
  .dynamic-input {
    // flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
  }
  .readonly {
    width: fit-content;
  }
}
.titleSingleTabs {
  display: none;
  color: #000;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

// curators
.userIcon {
  margin-right: 20px;
}
.credentialCurators {
  flex-wrap: wrap;
  & > div {
    flex: none !important;
  }
  .wrapper-dynamic-editor {
    min-width: auto;
  }
  .credential-title {
    color: #000;
    font-size: 24px;
  }
}
.companyCuratorsLabel {
  .readonly {
    color: #000;
    @extend .boldNormalSize;
  }
}
.tabCentralContent {
  margin-top: 20px;
  .tab-content {
    padding: 10px 5px;
    margin-bottom: 10px;
    border: 1px solid #c8c8c8;
    border-radius: 5px;
  }
  .dynamic-editor-label {
    @extend .boldNormalSize;
  }
  .tab-pane.active {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    gap: 1px;
  }
  .Input-email {
    align-items: start;
  }
}
.curatorsNote {
  line-height: 0.7;
  .dynamic-input {
    flex-direction: column;
    align-items: start;
  }
  .dynamic-editor-label {
    @extend .boldNormalSize;
  }
}

.Input-note > div:nth-child(2) {
  line-height: 1;
}

.linkDetails {
  text-transform: uppercase !important;
  &:hover {
    cursor: pointer;
    color: #369bff !important;
  }
}
.Input-preAssesstments {
  .accordion {
    width: 100%;
  }
}
.proceduresEditor {
  .proceduresEditorContainer {
    display: flex;
    div {
      flex: 1;
      flex-direction: row;
      display: flex;
      align-items: center;
    }
  }
}
.salesEditor {
  .salesEditorContainer {
    display: flex;
    flex-direction: column;
    div {
      flex: 1;
    }
    .salesInlineLabel {
      gap: 20px;
      flex: none;
      flex-direction: row;
      display: flex;
      .dynamic-editor-label {
        flex: none;
        display: flex;
        align-items: center;
      }
    }
  }
}
.lightLabel {
  gap: 20px !important;
  div {
    color: #848484;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    flex: none !important;
    text-transform: capitalize;
  }
}
.emailModal {
  max-width: 70%;
}
.templateEmailIcon {
  height: 30px;
  width: 30px;
  border: 1px solid rgb(201, 196, 196);
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.listEmailTemplate {
  height: 70vh;
  overflow: scroll;

  .listContent {
    border: 1px solid rgb(201, 196, 196);
    padding: 5px;
  }

  .templateEmail {
    p {
      margin: 0;
    }
    border: 1px solid rgb(201, 196, 196);
    padding: 5px 8px;
    cursor: pointer;
    margin: 5px 0;
    &.templateSelected {
      background-color: rgb(255, 255, 0, 0.5);
      border: 2px solid #369bff;
      border-radius: 4px;
    }
  }
}
.customBadge {
  text-transform: uppercase;
  padding: 5px 10px;
  font-size: 14px;
  color: white;
  font-family: Poppins;
  font-weight: 500;
  border-radius: 6px;
}
.photoNoteBar > div {
  flex: none !important;
}
.settigns-icon {
  align-self: start;
  cursor: pointer;
  display: flex;
  flex-wrap: nowrap;
  span {
    font-size: 20px;
  }
}
.settings-icon-in {
  margin-right: auto;
}
.settings-icon-out {
  margin-left: auto;
}
.tagged-users {
  margin-top: 5px;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  gap: 10px;
  .tagged-user {
    display: flex;
    flex-direction: row;
  }
}
.tagged-users-in {
  flex-direction: row-reverse;
}
.tagged-curators {
  margin-top: 5px;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  gap: 10px;
  .tagged-curator {
    display: flex;
    flex-direction: row;
  }
}

.photoNoteCustomStyle {
  display: contents !important;
}

.Input-auctionTermState > .readonly > .empty-placeholder {
  margin-left: auto;
}

.Input-auctionTermState button {
    min-width: auto;
    font-size: 11px;
    height: 25px;
    text-wrap: nowrap;
    position: relative;
    margin-left: auto;
}

.Input-attachments,
.Input-notes,
.Input-photos,
.Input-procedures,
.Input-companyCurators,
.Input-importDescription,
.Input-activitiesCode   ,
.Input-capital          ,
.Input-employees        ,
.Input-sales            ,
.Input-vatNumber        ,
.Input-fiscalCode       ,
.Input-codRea           ,
{
  background-color: #f8fafc;
  gap: 0px;
  border-radius: 0 10px 10px 10px;
}

.Input-importDescription,
.Input-activitiesCode   ,
.Input-capital          ,
.Input-employees        ,
.Input-sales            ,
.Input-vatNumber        ,
.Input-fiscalCode       ,
.Input-codRea
{
  padding-left: 10px;
  border-radius: 0;
}
