.input-form-field {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin: 10px;
    label {
        color: black;
        // text-transform: capitalize;
        // font-family: Poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-bottom: 0px;
    }
    .react-select__input {
        height: auto;
    }
    .react-select__control:hover {
        border: "1px solid #ececec";
    }
    input,select {
        border-radius: 6px;
        border: 1px solid #ececec;
        height: 35px;
        padding: 0px 15px;
    }
    textarea{
        padding: 10px 15px;
        border-radius: 6px;
        border: 1px solid #ececec;
    }
    input[type="checkbox"]{
        height: auto;
        width: fit-content;
    }
    input[type="radio"]{
        width: 20px;
    }
    .error-form {
        color: #ff365a;
    }
}
