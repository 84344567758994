.fc-button.fc-button-active {
  background-color: #e4e4e6 !important;
}

.fc-button {
  background-color: #f4f4f4;
}
.fc-header-toolbar {
  margin: 1.5em !important;
}
.fc .fc-list-empty {
  height: auto;
}

.calendarInterest {
  .fc-daygrid-event-dot {
    border-color: #d33f49 !important;
  }
}
.calendarWait {
  .fc-daygrid-event-dot {
    border-color: #ced0ce !important;
  }
}
.calendarEvaluation {
  .fc-daygrid-event-dot {
    border-color: #010001 !important;
  }
}
