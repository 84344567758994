.main_container_alarms {
    height: 80vh;
    width: 40vw;
    position:fixed;
    z-index: 1000;
    margin-left: 30%;
    margin-top: 3%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; 
    background-color: white;
    border-radius: 10px;
}

.header{
    width: 100%;
    height: 10vh;
    display: flex;
    align-items: center;
    gap: 20px;
    border-bottom: 1px solid var(--bs-card-border-color);
    .img_header{
        height: 35px;
        width: 35px;
    }
    .p_header{
        position: relative;
        top: 7px;
    }
    .close_alarms{
        margin-left: 60%;
        font-size: 20px;
        border: none;
        background-color: transparent;
        cursor: pointer;
    }
}

.container-content{
    width: 100%;
    height: 80vh;
    overflow-y: auto;
    display: flex;
    justify-content: center;
    .container_card{
        width: 90%;
    }
}

.footer{
    width: 100%;
    height: 10vh;
    display: flex;
    align-items: center;
    justify-content: right;
    border-top: 1px solid var(--bs-card-border-color);
    .button_footer{
        height: 30px;
        width: fit-content;
        background-color: blue;
        border: none;
        border-radius: 5px;
        color: white;
        margin-right: 30px;
    }
}