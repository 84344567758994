.pagination {
    justify-content: start;
    li {
        border-radius: 5px;
    }
    .pagination-actions-cells {
        background-color: #66b2ff;
    }
    .pagination-actions-cells,
    .pagination-current-page {
        &:hover {
            i {
                color: white !important;
            }
        }
        a.page-link,
        i {
            color: white;
            background-color: inherit;
            &:hover {
                background-color: inherit;
                color: white !important;
            }
        }
    }
    .pagination-current-page {
        background-color: #003366;
    }
}
