.edit-dynamic-component-structure{
    height: 100%;
    overflow: scroll;
    flex: 1;
    height: 100%;
    overflow: scrool;
}
.draggable {
    .grip {
        display: flex !important;
        opacity: 0;
    }
    .grip-row {
        position: relative;
        background-color: rgba(64, 66, 84, 1);
        display: flex;
        justify-content: flex-end;
        padding: 4px;
        gap:20px;
        padding-bottom: 15px;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
    }
    &:hover {
        .grip {
          opacity: 1;  
        }
        .container {
            border: 1px solid rgba(64, 66, 84, 1) !important;
        }
    }
}


.row-container {
    border: 1px solid #ececec;
    border-radius: 10px;
    margin-bottom: 10px;
    max-width: 100%;
    top: -10px;
}
