@import 'variables';
.list {
  .card {
    background-color: transparent;
  }
}
.tablesWidget {
  flex: 1;
  min-width: 100%;
  overflow-x: scroll;
  table {
    border-collapse: unset;
    border-spacing: 0px 4px !important;
    TBODY TR {
      border-bottom: none !important;
      margin-bottom: 4px;
    }
    .stateTableHeader {
      min-width: 4px;
    }
    .barStatus {
      content: "";
      position: absolute;
      width: 4px;
      height: 100%;
      background: lightgrey;
      border-radius: 6px;
    }

    td,
    th {
      padding: 10px;
      background-color: transparent;
    }
    td {
      border: 0;
    }
    th {
      min-width: 110px;
    }
    .row-element {
      background-color: white;
      max-width: 82px;
      max-height: 82px;
      position: relative;
      overflow: hidden;
      &:hover {
        opacity: 0.8;
        cursor: pointer;
      }
    }
    td:first-child,
    th:first-child {
      border-radius: 10px 0 0 10px;
    }
    td:last-child,
    th:last-child {
      border-radius: 0 10px 10px 0;
    }
  }
}


.customBorder-past {
  background: $company-past-border !important;
}

.customBorder-future {
  background: $company-future-border !important;
}

.customBorder-new {
  background: $company-new-border !important;
}

.customBorder-acquired {
  background: $company-acquired-border !important;
}

.customBorder-closed {
  background: grey !important;
}
.customRowBackground-Important {
  background: $company-important-background !important;
}

.customRowBackground-Urgent {
  background: $company-urgent-background !important;
}

.customRowBackground-closed {
  background: $company-closed-background !important;
}

.customRowBackground-acquired {
  background: $company-acquired-background !important;
}

.cellAsVote {
  H3 {
    color: #369bff;
  }
}

.selectCell {
  // max-width: 50px;
  min-width:50px !important;
  text-align: center;
  vertical-align: middle;
}

//pagination
.footerTable {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  .form-select {
    padding: 7px !important;
    display: flex;
    padding: 7px 3rem 7px 7px !important;
  }
}
.tableImports {
  display: table;
  .rowImports {
    display: table-row;
  }

  .cellImports {
    display: table-cell;
    width:calc(100%/ 5);
    min-width:300px;
    max-width:300px;
  }
  .cellStatusImports {
    display: table-cell;
    vertical-align: middle;
    > div {
      display: flex;
      min-width: 150px;
      max-width: 150px;
    }
  }
  .rowImports,
  .cellImports {
    padding: 10px;
  }
}
