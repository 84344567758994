@import "many-to-many-complex";
@import "paginator";
@import "button";
@import "input-form";
@import "edit";
@import "custom-editor-view";
@import "details";
@import "attachments";
@import "table";
@import "editors";
@import "../../node_modules/@yaireo/tagify/src/tagify";
@import "../sass/notification";
@import "alarms";
@import "wizard";

@import "fullcalendar";
@import "companyColumn";
body {
  margin: 0;
  font-family: "Poppins", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.small-button {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  padding: 0 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--bs-primary);
  color: white;
  border: none;
  outline: none;
  cursor: pointer;
  &:hover {
    background-color: var(--bs-primary);
  }
}
.app-header {
  background-color: #fff !important;
  border-bottom: 1px solid #ecf0f3 !important;
  z-index: 999 !important;
}
.show-on-hover {
  opacity: 0.5;
  transition: opacity 0.2s ease-in-out;
  &:hover {
    opacity: 1;
  }
}
// crud components
.titleList {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
}

.sidebarMenuItemSelected {
  background: rgba(197, 197, 197, 0.195976);
  border-radius: 6px;
}

//select
.form-select {
  width: auto !important;
}

.skeleton-list {
  display: flex;
  height: auto;
  flex-direction: column;
  justify-content: space-between;
  align-content: space-between;
  flex: 0 0 auto;

  span {
    display: block;
  }
}

// filter header
.filter-header {
  background: #ffffff;
  // box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.0813552);
  width: 100%;
  padding-top: 25px;
  padding-right: 20px;
  padding-left: 20px;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  align-items: center;
}

.autocomplete {
  position: relative;
  .options {
    position: absolute;
    background-color: #ffffff;
    width: 100%;
    list-style: none;
    min-width: 112px;
    max-width: 280px;
    overflow: auto;
    -webkit-overflow-scrolling: touch;

    max-width: none;
    max-height: 256px;
    width: 100%;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    li {
      white-space: nowrap;

      text-overflow: ellipsis;
      display: block;
      line-height: 48px;
      height: 48px;
      padding: 0 16px;
      text-align: left;
      text-decoration: none;
      max-width: 100%;
      position: relative;
      cursor: pointer;
      outline: none;
      display: flex;
      flex-direction: row;
      max-width: 100%;
      box-sizing: border-box;
      align-items: center;
    }
  }
}

//Modal
.modalDnd {
  width: 100vw !important;
  height: 100vh;
  position: fixed;
  z-index: 900;
  background-color: white;
  top: 0;
  right: -100vw;
  overflow: hidden;
  margin-top: 0 !important;

  transition: right 0.5s;
  color: black;
  display: flex;
  flex-direction: row;
  .container-header {
    padding: 30px 27px;
    flex: 1;
  }
  .header-actions {
    width: fit-content;
    margin-left: auto;
    display: flex;
    align-items: center;
    padding: 10px;
    i.bi {
      color: black;
      font-size: 20px;
      padding: 10px;
      &:hover {
        cursor: pointer;
      }
    }
    .header-actions {
      margin-left: auto;
    }
  }
  .container-modal {
    width: 100%;
    min-height: 100vh;
    overflow: scroll;
  }
}
.active-modal {
  right: 0;
}

//Loading
.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  position: fixed;
  background: rgba(255, 255, 255, 0.834);
}

.spinner {
  width: 64px;
  height: 64px;
  border: 8px solid;
  border-color: #3d5af1 transparent #3d5af1 transparent;
  border-radius: 50%;
  animation: spin-anim 1.2s linear infinite;
}

@keyframes spin-anim {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

// edit modal

.container-dynamiccomponent {
  padding: 10px;
  display: flex;
  flex-direction: row;
}
.empty-dynamic-component {
  cursor: pointer;
}
.disabled {
  color: grey !important;
}
.edit-mode-grip {
  background-color: rgba(64, 66, 84, 1);
  justify-content: flex-end;
  padding-bottom: 10px;
  padding-right: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.edit-mode-border {
  border: 2px solid rgba(64, 66, 84, 1) !important;
  border-radius: 10px !important;
  padding: 0px !important;
  margin-bottom: 10p !important;
}

.actionbar-save {
  margin: 0 10px;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}
.section-dynamic-component {
  display: flex;
  flex-direction: row;
}
.readonly {
  width: 100%;
  min-height: 32px;
  display: flex;
  align-items: center;
  gap: 5px;
  word-break: break-word;
}
.readonly-date {
  @extend .readonly;
  width: fit-content;
  margin-right: 10px;
}

.readonly-companiesuser {
  width: 100%;
  min-height: 32px;
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 5px;
  word-break: break-word;
}
.wrapper-dynamic-editor {
  width: 100%;
  min-width: 105px;
  min-height: 25px;

  .form-select {
    width: 100% !important;
    background-color: #e9e9e9 !important;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 5px;
  }
}
// .inputAsButtonEditor {
//   width: 100%;
//   max-width: 150px;
//   .btn {
//     padding: 3px 3px !important;
//   }
//   input {
//     background-color: transparent !important ;
//     color: white !important;
//     border: none !important;
//     margin: 0 !important;
//     padding: 0 !important;
//   }
// }
.icon-wrapper {
  text-align: center;
  background-color: #e5f2ff;
  width: 32px;
  height: 32px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  I {
    color: #369bff !important;
  }
}
.add-icon {
  background: #cce5ff !important;
  I {
    color: white !important;
  }
}
.dynamic-input {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
}
.input-custom {
  border-radius: 12px;
  background-color: #ececec;
  color: #c8c8c8 !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px 7px 6px 16px;
  I {
    padding: 2px;
  }
  INPUT {
    border: none;
    box-shadow: none;
    background-color: transparent;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    &:focus {
      outline: none;
      border: none;
    }
  }
}

//filters
.filters {
  .form-select {
    border-radius: 6px !important;
  }
}

.columnsList {
  list-style: none;
  padding-left: 0;
}

.columnsList li {
  display: flex;
  align-items: center;
  border: solid 2px #d0d0d0;
  font-size: 10px;
}

.columnsList p {
  max-width: none;
  margin: 0;
}
.columnsList .form-check-input {
  width: 0.75rem;
  height: 0.75rem;
}

.form-check-input, .Input-location {
  cursor: pointer;
}

.iconStandard {
  font-size: 20px !important;
  color: #369bff !important;
  cursor: pointer;
}

.iconAdd {
  vertical-align: middle;
}

//empty element
.empty-element {
  background: white;
  padding: 20px;
  border-radius: 12px;
  text-align: center;
  border: 1px solid var(--bs-gray-300);
  color: var(--bs-gray-700);
  /* font-family: "Poppins",sans-serif; */
  font-weight: 500;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  I {
    font-size: 40px;
  }
}
.skeleton-wrapper {
  width: 100%;
  SPAN {
    width: 100‰;
  }
}
.material-icons-two-tone {
  color: transparent !important;
  background: $primary;
  background-clip: text !important;
  -webkit-background-clip: text !important;
}
.empty-placeholder {
  color: #a0a0a0 !important;
}
.drawer-notification{
  z-index:600;
}
.w-fit{
  flex-shrink: 0;
  width: fit-content;
}
