.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.app-logo {
  position: relative;
  
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.app-logo > .app-img {
  position: relative;
  z-index: 1;

  height: 150px; width: 150px;
  pointer-events: none;

  animation: app-img-pulse 2s infinite;
}

@keyframes app-img-pulse {
  0% {
    transform: scale(0.95);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.95);
  }
}

.app-logo > .blob {
  position: absolute;
  z-index: 0;

	background: rgb(255, 255, 255);
	border-radius: 50%;
	margin: 10px;
	
  height: 70px; width: 70px;

	box-shadow: 0 0 0 0 rgb(255, 255, 255);
	transform: scale(1);
	animation: blob-pulse 2s infinite;
}
.menu-title{
  color: var(--bs-gray-700) !important;
}
@keyframes blob-pulse {
	0% {
		transform: scale(0.80);
		box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
	}

	100% {
		transform: scale(0.80);
		box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
	}
}

.max-w-full {
  max-width: 100%;
}

.inline{
  display: inline;
}

.google-icon {
  background: conic-gradient(from -45deg, #ea4335 110deg, #4285f4 90deg 180deg, #34a853 180deg 270deg, #fbbc05 270deg) 73% 55%/150% 150% no-repeat;
  background-clip: text;
  color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
