.many-to-many-complex {
    flex: 1;
}
.mainFieldActive {
    cursor: pointer;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background-color: #369bff;
}
.mainFieldDisable{
    cursor: pointer;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    border:1px solid #369bff;
}

TBODY {
    TR {
        border-bottom: 1px solid #ececec !important;
    }
}
.add-icon {
    margin-top: 20px;
}
.credential {
    .topFields {
        font-size: 14px;
        font-weight: 500;
        line-height: 21px;
        letter-spacing: 0px;
        text-align: left;
    }
    .bottomFields {
        font-size: 12px;
        font-weight: 500;
        line-height: 18px;
        letter-spacing: 0px;
        text-align: left;
        color: #c8c8c8;
    }
    &:hover{
        cursor: pointer;
        color:#369bff !important;
    }
}
.header-mmTable {
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.label-mmTable {
    color: #000;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-left:10px;
}
.actions-cell {
    .icon-wrapper {
        margin-left: auto;
    }
}
