.dynamic-editor-label {
    color: #000;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    text-transform: capitalize;
    white-space: nowrap;
}
.dynamic-editor-icon {
    position: absolute;
    right: 6px;
}

.classWithIcon {
    position: relative;
    padding-left: 5px;
}
.icon-editor {
    font-size: 20px !important;
}
.icon-plus {
    @extend .icon-editor;
    color: #369bff !important;
}
.section-central-content {
    max-width: 30%!important;
    margin: 0 5px;
    .tab-content {
        //padding-left: 30px;
    }
    .dynamic-input {
        flex-wrap: wrap;
    }
    .activiesCode {
        max-width: 200px !important;
    }
    .sales {
        position: relative;
        top: 8px;
        .readonly {
            height: fit-content;
            position: relative;
            bottom: 10px;
        }
    }
    .employes {
        position: relative;
        bottom: 3px;
        .readonly {
            height: fit-content;
            position: relative;
            bottom: 10px;
        }
    }
    .capitale {
        position: relative;
        bottom: 13px;
        .readonly {
            height: fit-content;
            position: relative;
            bottom: 10px;
        }
    }
    .vatNumber {
        position: relative;
        bottom: 20px;
        .readonly {
            height: fit-content;
            position: relative;
            bottom: 10px;
        }
    }

    .fiscalCode {
        position: relative;
        bottom: 25px;
        .readonly {
            height: fit-content;
            position: relative;
            bottom: 10px;
        }
    }

    .codRea {
        position: relative;
        bottom: 32px;
        .readonly {
            height: fit-content;
            position: relative;
            bottom: 10px;
        }
    }
}
.assessmentsRow{
    gap: 20px !important;
     > div{
        flex:none !important;
     }
   .wrapper-dynamic-editor{
    max-width: fit-content;
    min-width: none;
    flex:none;
   }
}
.label-normal {
    font-size: 12px;
    font-weight: 400;
    text-wrap: nowrap;
}
.title-single-tab {
    font-size: 13px;
    font-weight: 700;
}
.button-postpone {
    min-width: auto;
    font-size: 11px;
    color: #3699ff;
    height: 25px;
    text-wrap: nowrap;
    position: relative;
    margin-left: auto;
}
.section-left {
    align-items:start !important;
    .location-text {
        color: #1b283f !important;
    }

    .address-text {
        color: #1b283f !important;
        text-transform: capitalize;
    }

    .alert-row {
        .readonly {
            width: fit-content;
        }
    }
}
