@import 'variables';
.customBorderCard {
    border-top: 1px solid #C8C8C8;
    border-right: 1px solid #C8C8C8;
    border-bottom: 1px solid #C8C8C8;
    border-left: 5px solid #C8C8C8;
}

.no-border {
    border-top: 0;
    border-right: 0;
    border-bottom: 0;
}

.customBorderFuture {
    @extend .customBorderCard;
    border-left-color: $company-future-border !important;
}

.customBorderPast {
    @extend .customBorderCard;
    border-left-color: $company-past-border !important;
}

.customBorderCardNuova {
    @extend .customBorderCard;
    border-left-color: $company-new-border !important;
}
.customBorderAcquired {
    @extend .customBorderCard;
    border-left-color: $company-acquired-border !important;
}

.customBorderGray {
    @extend .customBorderCard;
    border-left-color: grey !important;
}

.customBackgroundImportant {
  background: $company-important-background !important;
}

.customBackgroundImportantDark {
  background: $company-important-background-dark !important;
}

.customBackgroundUrgent {
  background: $company-urgent-background !important;
}

.customBackgroundUrgentDark {
  background: $company-urgent-background-dark !important;
}
.customBackgroundFuture {
  background: $company-future-background !important;
}
.customBackgroundPast {
  background: $company-past-background !important;
}
.customBackgroundNew {
  background: $company-new-background !important;
}
.customBackgroundClosed {
  background: $company-closed-background !important;
}

.customBackgroundAcquired {
  background: $company-acquired-background !important;
}
