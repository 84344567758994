//
// Timeline
//
@import 'variables';
// Base
.timeline {
    // Item
    .timeline-item {
      position: relative;
      padding: 0;
      margin: 0;
      display: flex;
      align-items: flex-start;
  
      &:last-child {
        .timeline-line {
          bottom: 100%;
        }
      }
    }
  
    // Line
    .timeline-line {
      display: block;
      content: ' ';
      justify-content: center;
      position: absolute;
      z-index: 0;
      left: 25px;
      top: 75px;
      bottom: 0;
      transform: translate(50%);
      border-left-width: 4px;
      border-left-style: solid;
      border-left-color: var(--#{$prefix}gray-300);
    }
  
    // Icon
    .timeline-icon {
      z-index: 1;
      flex-shrink: 0;
      margin-right: 1rem;
      text-align: center;
    }
  
    // Content
    .timeline-content {
      width: 100%;
      overflow: auto;
      margin-bottom: 1.5rem;
    }
  
    // Vertical center
    &.timeline-center {
      .timeline-item {
        align-items: center;
  
        &:first-child {
          .timeline-line {
            top: 50%;
          }
        }
  
        &:last-child {
          .timeline-line {
            bottom: 50%;
          }
        }
      }
    }
  }
  