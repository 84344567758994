.wizard{
    background-color: #f4f6fa;
    .riepilogo{
        .riepilogo-row{
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-auto-rows: auto;
            grid-gap: 1rem;
            .riepilogo-col{
                box-sizing: border-box;
            }
        }
    }
}