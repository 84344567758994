$color-black: #000000; // Valore hex per il colore nero
$color-blue: #007bff; // Valore hex per il colore blu
$color-red: #ff0000;
$colors: (
  black: $color-black,
  blue: $color-blue,
  red: $color-red,
  // Altri colori...
);

@function str-replace($string, $search, $replace: "") {
  $index: str-index($string, $search);
  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace +
      str-replace(
        str-slice($string, $index + str-length($search)),
        $search,
        $replace
      );
  }
  @return $string;
}
​ :root {
  --custom-color: #000;
}
$custom-color: var(--custom-color);
.button-normalize {
  min-width: 105px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: fit-content;
  outline: none;
  border: none;
  padding: 10px;
  border-radius: 5px;
  text-transform: capitalize;
  font-size: 12px;
}

.button-primary-extended {
  background-color: #d6eaff;
  color: #3699ff !important;
  i {
    color: #3699ff;
  }
}
.button-select-extended {
  border-radius: 6px;
  padding: 5px;
}
.button-editor-extended {
  background-color: #369bff;
  border-radius: 6px !important;
  padding: 5px;
  font-size: 16px;
  font-weight: 500;
  color: white !important;
  i {
    color: white;
  }
}
.button-disabled-extended {
  background-color: #ececec !important;
  color: #c8c8c8 !important;
  i {
    color: #c8c8c8 !important;
  }
}
.button-secondary-extended {
  background-color: #ffd6dd;
  color: #ff365a !important;
  i {
    color: #ff365a;
  }
}
@each $color-name, $color-value in $colors {
  .button-#{$color-name}-extended {
    background-color: transparentize($color-value, 0.8);
    color: $color-value;
  }
}
.button-custom-color {
  background-color: rgba($custom-color, 0.2);
  color: rgb($custom-color) !important;
}

.button-custom-negative {
  background-color: rgb($custom-color);
  color: white !important;
}
