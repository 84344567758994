.edit-wrapper {
  display: flex;
  flex-direction: row;
  padding: 18px;
  overflow: hidden;
  .edit-content {
    display: flex;
    max-height: 100vh;
    flex-direction: column;
    flex: 1;
    overflow: scroll;
    background-color: white;
    height: 100%;
    .edit-header {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      background-color: #1e1e2d;
      text-transform: capitalize;
      padding: 24px;
      h2,
      i {
        color: white;
      }
      i {
        font-size: 24px;
      }
    }
    .edit-actions {
      display: flex;
      width: 100%;
      margin: 20px;
      gap: 20px;
    }
  }
  .edit-bar {
    max-width: 245px;
    width: 100%;
    background-color: #404254;
    height: auto;
    min-height: 100vh;
    overflow: scroll;
    display: flex;
    flex-direction: column;
    padding: 50px 16px;

    .title-edit-bar {
      color: rgba(255, 255, 255, 0.5);
      font-size: 14px;
      font-weight: 600px;
      text-transform: uppercase;
    }
    .label-edit-bar {
      color: rgba(255, 255, 255, 1);
      font-size: 12px;
      font-weight: 400;
      text-transform: capitalize;
      white-space: pre;
      word-wrap: break-word;
      cursor: pointer;
    }
    .modal-header {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }
  }
  .row-droppable {
    flex-wrap: wrap;
    & > div {
      flex: 1;
    }
  }
}
.label-column {
  margin-top: 20px;
  .wrapper-dynamic-editor {
    .dynamic-input {
      flex-direction: column;
      align-items: start;
    }
  }
}
.separator-vertical {
  flex: none !important;
  margin: 0 10px;
  DIV {
    background-color: #ececec !important;
  }
}

